<template>
  <div id="serve">
    <div class="serves">
      <Top />
    </div>
    <!-- 内容 -->
    <div class="servecont">
      <div class="jianjie-title" style="text-align: center">
        <div class="jianjie-title-e">ABOUT ZHUANG BA</div>
        <div class="jianjie-title-c">凯洋服务</div>
      </div>
      <div class="servecont-cont">
        <ul>
          <li>
            <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/f1c.png" alt="" />
            <div class="fctitle">国际海运</div>
            <div class="fctext">
              提供订舱、租船、报关、堆场装箱、产地装箱、商检、动植物检、危险品申报、仓储及地面运输服务。
            </div>
          </li>
          <li class="litextp">
            <p>
              国际海运是凯洋的主营业务之一。经过多年的发展，公司已培养出一大批经验丰富的海运专业人才，熟悉世界各国港口情况和各类货物运输流程，精于办理各种货运手续。同时公司已与世界各主要船公司建立了良好的战略合作关系。
            </p>
            <a href="/fdetail?cid=0">了解更多</a>
          </li>
          <li class="litextp">
            <p>
              凯洋国际空运致力拓展国际航空货物运输，经多年努力积累了丰富的航空货运经验，拥有一流的专业操作团队，为客户提供“专业、快捷、安全”的空运服务。我们以广州、香港机场为中心，与十几家知名航空公司建立了密切的合作关系，能够在确保舱位的前提下获得理想的协议运价，能够很好地为国内外的生产商和进口商降低的运营成本。
            </p>
            <a href="/fdetail?cid=1">了解更多</a>
          </li>
          <li>
            <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/f2c.png" alt="" />
            <div class="fctitle">国际空运</div>
            <div class="fctext">
              主营国际航空非洲、东南亚、日本、中东、红海、美加、欧洲等
            </div>
          </li>
          <li>
            <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/f3c.png" alt="" />
            <div class="fctitle">东南亚陆运</div>
            <div class="fctext">
              一站式系统为客户提供足不出户即能买遍天下货的优质可靠的服务。
            </div>
          </li>
          <li class="litextp">
            <p>
              凯洋以海量的商品选择、优惠的价格收费、便捷的支付通道、快捷的物流运输和可靠的售后服务为理念的一站式系统为客户提供足不出户即能买遍天下货的优质可靠的服务。
            </p>
            <a href="/fdetail?cid=2">了解更多</a>
          </li>
          <li class="litextp">
            <p>
              凯洋以海量的商品选择、优惠的价格收费、便捷的支付通道、快捷的物流运输和可靠的售后服务为理念的一站式系统为客户提供足不出户即能买遍天下货的优质可靠的服务。
            </p>
            <a href="/fdetail?cid=3">了解更多</a>
          </li>
          <li>
            <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/f4c.png" alt="" />
            <div class="fctitle">国际电商</div>
            <div class="fctext">
              自营仓库分布于东莞、广州、深圳、越南、缅甸、马来西亚、美国、日本
            </div>
          </li>
          <li>
            <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/f5c.png" alt="" />
            <div class="fctitle">仓储服务</div>
            <div class="fctext">
              凯洋得以为广大客户提供优质，快速，价廉的中尼物流解决方案，是您在尼日利亚物流服务上的不二选择。
            </div>
          </li>
          <li class="litextp">
            <p>
              凯洋以海量的商品选择、优惠的价格收费、便捷的支付通道、快捷的物流运输和可靠的售后服务为理念的一站式系统为客户提供足不出户即能买遍天下货的优质可靠的服务。
            </p>
            <a href="/fdetail?cid=4">了解更多</a>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Top from "../components/topnav.vue";
export default {
  components: {
    Footer,
    Top,
  },
};
</script>

<style lang="scss" scoped>
#serve {
  width: 100%;
}
.serves {
  width: 100%;
  height: 500px;
  background: url(https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/bannerc.png) no-repeat;
  background-size: 100%;
}
// 内容
.servecont {
  width: 1200px;
  margin: 100px auto;
  &-cont {
    > ul {
      margin-top: 28px;
      display: flex;
      flex-wrap: wrap;
      > li {
        width: 50%;
        height: 480px;
        background-color: #eeeeee;
        position: relative;
        > img {
          width: 100%;
          height: 100%;
        }
        > .fctitle {
          position: absolute;
          top: 155px;
          left: 50%;
          transform: translateX(-51%);
          text-align: center;
          font-size: 48px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          z-index: 2;
        }
        > .fctext {
          position: absolute;
          top: 237px;
          left: 50%;
          transform: translateX(-51%);
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          width: 340px;
        }
      }
      //  文字部分内边距
      > .litextp {
        padding: 102px 31px 0 31px;
        box-sizing: border-box;
        > p {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          text-indent: 30px;
        }
        > a {
          width: 150px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background-color: #f29709;
          color: white;
          display: inline-block;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
          margin-top: 56px;
        }
      }
    }
  }
}
.jianjie-title-e {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #444444;
}
.jianjie-title-c {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-top: 14px;
}
</style>